<template>
  <div class="news4">
    <div class="container">
      <el-row :gutter="24">
        <el-col :span="18" style="margin-bottom: 56px">
          <div style="background-color: #fff">
            <div class="news4__title-container">
              <span>首页 > </span>
              <span>新闻资讯 > </span>
              <span>资讯详情</span>
            </div>
            <div class="news4__content-left">
              <div class="news4-title">什么是PCBA代工代料，PCBA代工代料有哪些类型</div>
              <div class="news4-createTime">
                <span>发布时间： 2021/1/19 17:47:55 </span>
                <span>浏览： 68次</span>
              </div>
              <div style="font-size: 16px;padding-bottom: 64px;border-bottom: 1px solid #d4d4d4">
                <div class="news4-subTitle">1、什么是PCBA代工代料？</div>
                <div class="news4-content">
                  <p> 狭义的PCBA代工代料指提供PCB板、电子元器件代购及PCBA加工的服务。</p>
                  <p>广义的PCBA代工代料指提供从电子方案设计、电子产品开发到物料代购、样机打样测试、后期批量加工生产的一整套PCBA加工服务。</p>
                </div>
                <div class="news4-subTitle">2、PCBA代工代料有哪些类型呢？</div>
                <div class="news4-subTitle-subTitle">2.1.技术主导型</div>
                <div class="news4-content">主要侧重于电子方案开发（或正向、或逆向），电子产品设计。公司重点在于电子产品研发上，同时也能提供中小批量的PCBA加工及相应的物料代购。</div>
                <div class="news4-subTitle-subTitle">2.2.加工主导型</div>
                <div class="news4-content">公司拥有数条SMT贴片加工生产线，能够提供高效的电子产品加工服务。但在产品开发上提供的资源较少，一般需客户提供相关的技术文件进行加工。</div>
                <div class="news4-subTitle">3、选择PCBA代工代料厂家需考虑哪些内容？</div>
                <div class="news4-content">
                  怎样选择PCBA代工代料厂家，这需要结合自身的需求出发，如果自己有相关PCB文件只需后续的PCBA加工生产，通常选择加工主导型PCBA代工代料工厂在经验和价格上具备较大优势，尤其是一些大批量的消费、安防等电子产品加工。如果自己的产品需从零开发，如一些专业的工控电子主板、仪器仪表控制系统、汽车设备控制板等，选择技术主导型PCBA代工代料厂相对更好。
                </div>
              </div>

              <div style="padding-top: 20px;padding-bottom: 40px">
                <router-link to="/news5">
                  <span style="color: #DA261D;font-size: 16px">< 上一篇</span>
                </router-link>
				<router-link to="/news3">
				 <span class="fr" style="color: #DA261D;font-size: 16px">下一篇 ></span>
				</router-link>
              </div>
            </div>
          </div>
        </el-col>
        <el-col :span="6">
          <div style="background-color: #fff;padding: 24px">
            <div style="font-size: 20px;font-weight: bold">热点资讯</div>
            <div style="border-bottom: 1px solid #D4D4D4">
              <p style="margin-top: 18px;margin-bottom: 8px;font-size: 16px">SMT贴片检验有哪些标准</p>
              <p style="font-size: 12px;color: #A6A6A6;margin-bottom: 8px">2021-01-22 15:47</p>
            </div>
            <div style="border-bottom: 1px solid #D4D4D4;margin-top: 32px">
              <p style="margin-top: 18px;margin-bottom: 8px;font-size: 16px">SMT贴片检验SMT对于电子行……</p>
              <p style="font-size: 12px;color: #A6A6A6;margin-bottom: 8px">2021-01-22 15:47</p>
            </div>
            <div style="border-bottom: 1px solid #D4D4D4;margin-top: 32px">
              <p style="margin-top: 18px;margin-bottom: 8px;font-size: 16px">什么是PCBA代工代料，PCBA……</p>
              <p style="font-size: 12px;color: #A6A6A6;margin-bottom: 8px">2021-01-22 15:47</p>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss">
@import "@/styles/common.scss";

.news4 {
  background: #F6F6F6;

  .container {
    margin-top: 24px;
    padding: 0 gap();
  }
}

.news4__title-container {
  font-size: 14px;
  padding-top: 16px;
  padding-left: 20px;
  padding-bottom: 20px;
  background-color: #fff;

  span:nth-of-type(3) {
    color: #a6a6a6;
  }
}

.news4__content-left {
  margin-left: 20px;
  margin-right: 20px;
  background-color: #fff;

  .news4-title {
    font-size: 24px;
    font-weight: bold;
  }

  .news4-createTime {
    padding: 10px 0;
    font-size: 14px;
    color: #A6A6A6;
    border-bottom: 1px solid #D4D4D4;

    span:first-of-type {
      margin-right: 32px;
    }
  }

  .news4__p-1 {
    margin: 20px 0;

    line-height: 1.5;
  }

  .news4__p-2,
  .news4__p-3,
  .news4__p-4 {

    line-height: 1.5;
  }
}

.news4-subTitle {
  font-size: 16px;
  font-weight: bold;
  margin: 20px 0 8px 0;
}

.news4-subTitle-subTitle {
  margin: 10px 0;
}

.news4-content {
  text-indent: 2em;
  line-height: 1.5;
}
</style>
